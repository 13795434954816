<template>
  <div class="user">
    <div class="block-quote">
      <el-form :inline="true">
        <el-form-item label="续租设备:">
          <el-input
            style="width: 200px"
            v-model="box_name"
            clearable
            placeholder="请输入续租设备:"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户id:">
          <el-input
            style="width: 200px"
            v-model="uid"
            clearable
            placeholder="请输入用户id:"
          ></el-input>
        </el-form-item>
        <el-form-item label="续租订单:">
          <el-input
            style="width: 200px"
            v-model="order"
            clearable
            placeholder="续租订单"
          ></el-input>
        </el-form-item>
        <el-form-item label="时间:">
          <el-date-picker
            v-model="time"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item style="float: right">
          <el-button type="primary" icon="el-icon-search" @click="searchinfo"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <page-table
      ref="dataTable"
      :data="userList"
      @changeCurrentPage="changeCurrent"
    >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{
            (page.currentPage - 1) * page.pageSize + scope.$index + 1
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="uid" label="用户id" align="center">
      </el-table-column>
      <el-table-column prop="order" label="续租订单" align="center">
      </el-table-column>
      <el-table-column prop="pay_type" label="支付方式" align="center">
        <template slot-scope="scope">
          <el-link v-if="scope.row.pay_type==1" style="color:green">微信支付</el-link>
          <el-link v-if="scope.row.pay_type==2" style="color:red">支付宝支付</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="box_name" label="续租设备" align="center">
      </el-table-column>
      <el-table-column label="续租周期" align="center">
        <template slot-scope="scope">
          <el-link @click="showtable(scope.row)">{{
            scope.row.list_id.split(",").length
          }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="pay_time" label="续租时间" align="center">
        <template slot-scope="scope">
          <span> {{ (scope.row.pay_time * 1000) | Time }}</span>
        </template>
      </el-table-column>
      <el-table-column label="使用收益" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.ads_price}}</span>
          <span >({{scope.row.ads_use}})</span>
        </template>
      </el-table-column>
      <el-table-column prop="order_price" label="支付金额" align="center">
      </el-table-column>
    </page-table>

    <!-- 新增编辑弹窗 -->
    <edit-data ref="editData" />
  </div>
</template>

<script>
import { reletOrder } from "@/request/api";
import moment from "moment";
import pageTable from "@/components/pageTable.vue";
import { checkPermission } from "@/utils/permissions";
import editData from "./components/editData.vue";

export default {
  name: "user",
  components: {
    pageTable,
    editData,
  },
  data() {
    return {
      order: "",
      box_name: "",
      uid: "",
      time: "",
      userList: [],

      page: {
        //分页信息
        currentPage: 1, //当前页
        pageSize: 10, //每页条数
        total: 0, //总条数
      },
    };
  },
  watch: {
    time(newVal) {
      if (newVal == null) {
        this.time = [];
      }
    },
  },
  filters: {
    Time(e) {
      //处理时间
      return moment(parseInt(e)).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  created() {
    this.getUserList(); //获取用户列表
  },
  mounted() {},
  computed: {},
  methods: {
    showtable(row) {
      let rowData = row;
      this.$refs.editData.show(JSON.parse(JSON.stringify(rowData)));
    },
    checkPermission,
    // 切换分页
    changeCurrent(page, size) {
      this.page.currentPage = page;
      this.page.pageSize = size;
      this.getUserList();
    },
    searchinfo() {
      this.page.currentPage = 1;
      let token = sessionStorage.getItem("token");
      this.token = token;
      let params = {
        page: 1,
        limit: this.page.pageSize,
        token: sessionStorage.getItem("token"),
        order: this.order,
        box_name: this.box_name,
        uid: this.uid,
        time1: this.time[0],
        time2: this.time[1],
      };
      reletOrder(params).then((res) => {
        this.page.total = res.data.count;
        this.userList = res.data.data;
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      });
    },
    getUserList() {
      let token = sessionStorage.getItem("token");
      this.token = token;
      let params = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        token: sessionStorage.getItem("token"),
        order: this.order,
        box_name: this.box_name,
        uid: this.uid,
        time1: this.time[0],
        time2: this.time[1],
      };
      reletOrder(params).then((res) => {
        this.page.total = res.data.count;
        this.userList = res.data.data;
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
